<template>
  <div id="suppliers">
    <b-modal
      id="delete-supplier-modal"
      v-model="showModal"
      title="Supplier Deletion Confirmation"
      @ok="doubleDeleteModal(true)"
    >
      <p class="my-2">Are you sure you would like to delete this supplier?</p>
    </b-modal>

    <b-modal
      id="double-delete-supplier-modal"
      v-model="showDoubleModal"
      ref="my-modal"
      title="Double Deletion Confirmation"
      @ok="confirmedDelete"
      :ok-disabled="blockDelete"
    >
      <p id="countdown">{{ count }}</p>
      <p class="my-2">
        Deleting a supplier could create unexpected errors if it is in use. Please be careful, only
        delete a supplier if you're certain it has never been used. Are you sure you want to delete
        the supplier?
      </p>
    </b-modal>

    <div>
      <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />

      <b-container>
        <h1>Suppliers</h1>
        <div class="d-flex justify-content-between align-items-end">
          <b-button class="mb-3" variant="outline-primary" :to="{ name: 'admin-supplier-create' }"
            >Create New Supplier</b-button
          >
          <b-button
            class="mb-3"
            variant="outline-secondary"
            size="sm"
            :to="{ name: 'spotlight-rules' }"
            >Spotlight Rules</b-button
          >
        </div>

        <!-- Search section -->
        <b-form-group class="mb-4" @keydown.native.esc="clearSearch">
          <!-- Search Bar -->
          <b-input-group>
            <b-form-input v-model="filter" placeholder="Type to Search" />
            <b-input-group-append>
              <!-- Select Categories -->
              <b-dropdown variant="primary" text="Filter by categories">
                <b-dropdown-item
                  @click="appendCategoryToSelected(option)"
                  v-for="option in options"
                  v-bind:key="option.value"
                >
                  <span :id="option.value">{{ supplyTypeDisplay(option.value) }}</span>
                </b-dropdown-item>
              </b-dropdown>

              <!-- Clear inputs -->
              <b-btn @click="clearSearch">Clear</b-btn>
            </b-input-group-append>
          </b-input-group>

          <!-- Categories selected list -->
          <div class="pt-3" v-if="selected.length > 0">
            <div
              class="categoryDiv d-inline"
              @click="removeCategoryFromSelected(category)"
              v-for="category in selected"
              v-bind:key="category"
            >
              <span class="categoriesAdded">
                {{ supplyTypeDisplay(category) }}
                <font-awesome-icon class="ml-2" :icon="['fas', 'times-circle']" />
              </span>
            </div>
          </div>
        </b-form-group>

        <b-pagination
          v-show="filteredSuppliers.length > perPage"
          class="float-right"
          v-model="currentPage"
          :total-rows="filteredSuppliers.length"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>

        <b-table
          busy.sync="loading"
          show-empty
          :items="filteredSuppliers"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template slot="empty">
            <div v-if="loading" class="text-center">
              <Busy primary text="Loading..." />
            </div>
            <div v-else class="text-center">No suppliers found.</div>
          </template>

          <!-- Action Button -->
          <template v-slot:cell(actions)="row">
            <b-dropdown text="Actions">
              <!-- Supplier Details -->
              <b-dropdown-item
                :to="{ name: 'supplier-details', params: { supplierId: row.item.supplierId } }"
              >
                <font-awesome-icon :icon="['fas', 'info-circle']" class="text-muted mr-2" />Details
              </b-dropdown-item>

              <!-- Spotlight rules search -->
              <b-dropdown-item :to="{ name: 'spotlight-rules', query: { q: row.item.name } }">
                <font-awesome-icon :icon="['fas', 'whale']" class="text-muted mr-2" />Spotlight
                Rules
              </b-dropdown-item>

              <!-- Supplier Edit -->
              <div v-if="isAdmin">
                <b-dropdown-divider />
                <b-dropdown-item
                  :to="{
                    name: 'admin-supplier-edit',
                    params: { supplierId: row.item.supplierId }
                  }"
                >
                  <font-awesome-icon :icon="['far', 'edit']" class="text-muted mr-2" />Edit Supplier
                </b-dropdown-item>
              </div>

              <!-- Delete Supplier -->
              <div v-if="isAdmin">
                <b-dropdown-item @click="deleteHandler(row.item.supplierId)">
                  <font-awesome-icon
                    :icon="['fas', 'trash']"
                    class="mr-1 text-muted space-right"
                  />Delete supplier
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </template>

          <template v-slot:cell(spotlight)="row">
            <font-awesome-icon
              v-if="spotlightRules[row.item.supplierId]"
              :icon="['fas', 'whale']"
              v-b-popover.hover.top="'Included in Spotlight'"
            />
          </template>
        </b-table>
        <b-pagination
          v-show="filteredSuppliers.length > perPage"
          class="float-right mb-5"
          v-model="currentPage"
          :total-rows="filteredSuppliers.length"
          :per-page="perPage"
        ></b-pagination>
      </b-container>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#suppliers {
  .fa-times-circle,
  .categoryDiv {
    cursor: pointer;
  }

  .categoriesAdded {
    position: relative;
    border: 1px solid $color-font-para;
    padding: 8px;
    border-radius: 5px;
    margin-left: 4px;
    color: $color-font-para;
  }

  #countdown {
    font-size: 25px;
    font-style: "bold";
    text-align: center;
  }

  .color-pink {
    color: $color-pink-main;
  }
}
</style>

<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import { EventBus } from "@/components/eventbus";
import CognitoAuth from "@/cognito/cognitoauth";
import FormatHelper from "@/helper/formathelper";
import SupplyTypes from "@/helper/supplytypehelper";
import Busy from "@/components/Busy";

export default {
  name: "Supplier",
  components: { Busy },
  data() {
    return {
      title: "Suppliers",
      isAdmin: false,
      loading: true,

      //Table of filtered results
      perPage: 50,
      currentPage: 1,
      fields: [
        { key: "actions", label: "", class: "text-center align-middle" },
        {
          key: "supplierId",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        { key: "spotlight", label: "", class: "color-pink" },
        { key: "name", label: "Name", sortable: true },
        { key: "categories", label: "Category", formatter: "formatCategories" }
      ],
      suppliers: [],
      spotlightRules: {},

      //Multiselect tool of categories
      options: [{ value: "Broker" }],
      selected: [],

      //Search bar
      filter: null,
      supplierId: null,
      supplierDeleteId: null,
      showModal: false,
      showDoubleModal: false,
      blockDelete: true,
      count: 0
    };
  },
  created() {
    this.supplyTypes().forEach(element => {
      this.options.push({ value: element });
    });

    this.fetchData();
  },
  computed: {
    filteredSuppliers() {
      let result = this.suppliers;
      if (this.filter) {
        result = this.suppliers.filter(supplier => {
          return supplier.name.toLowerCase().includes(this.filter.toLowerCase());
        });
      }
      if (this.selected.length > 0) {
        result = result.filter(supplier => {
          if (this.selected.includes("Broker")) {
            return supplier.broker;
          }
          return (
            supplier.categories &&
            supplier.categories.some(category => this.selected.indexOf(category) >= 0)
          );
        });
      }
      return result;
    }
  },
  mounted() {
    this.options.sort(o => (o.value ? 1 : -1));
  },
  methods: {
    formatCoboldCase: FormatHelper.formatCoboldCase,
    supplyTypes: SupplyTypes.supplyTypes,
    supplyTypeDisplay: SupplyTypes.toDisplay,

    appendCategoryToSelected(option) {
      let index = this.options.indexOf(option);
      this.options.splice(index, 1);
      this.selected.push(option.value);
    },

    removeCategoryFromSelected(category) {
      this.options.push({ value: category });
      this.options.sort(o => (o.value ? 1 : -1));

      this.selected.splice(this.selected.indexOf(category), 1);
    },

    async fetchData() {
      this.isAdmin = await CognitoAuth.isCurrentUserAdmin();
      this.name = null;
      var summarised = true;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(
          `${ApiHelper.endPoint()}suppliers?spotlight=true&summarised=${summarised}`
        );
        if (response.data.status === "success") {
          Object.keys(response.data.suppliers).forEach(key => {
            this.suppliers.push(response.data.suppliers[key]);
          });
          if (response.data.spotlightRules) {
            this.spotlightRules = response.data.spotlightRules;
          }
        } else {
          this.showMessage("A problem occured in searching for data.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("A problem occured in searching for data.", "warning");
      } finally {
        this.loading = false;
      }
    },

    //DELETE SUPPLIER BY ID
    deleteHandler(supplierDeleteId) {
      this.showModal = true;
      this.supplierDeleteId = supplierDeleteId;
    },

    hideModal(countdown) {
      clearInterval(countdown);
      this.showDoubleModal = false;
    },

    //DELETE SUPPLIER BY ID
    countDownTimer(countdown) {
      this.blockDelete = true;
      this.count = 10;
      if (countdown > 0) {
        setTimeout(() => {
          countdown -= 1;
          this.countDownTimer(countdown);
        }, 1000);
        this.count = countdown;
      } else {
        this.blockDelete = false;
      }
    },

    doubleDeleteModal(value) {
      this.showDoubleModal = value;

      if (value == true) {
        this.countDownTimer(10);
      }
    },

    async confirmedDelete() {
      try {
        const client = await ApiHelper.http();
        let response = await client.delete(
          `${ApiHelper.endPoint()}suppliers/${this.supplierDeleteId}`
        );
        if (response.data.status === "success") {
          Console.log(response);

          const index = this.suppliers.findIndex(
            supplier => supplier.supplierId == this.supplierDeleteId
          ); // find the item index
          if (~index) {
            // if the item exists in array
            this.suppliers.splice(index, 1); //delete the item
          }

          this.name = null;
        } else {
          this.showMessage("There was a problem deleting the supplier.", "warning");
        }
      } catch (error) {
        Console.error(error);
        this.showMessage("There was a problem deleting the supplier.", "warning");
      }
    },

    //CLEAR SEARCH
    clearSearch() {
      this.filter = "";

      //clear categories
      if (this.selected != []) {
        this.selected.forEach(element => {
          this.options.push({ value: element });
        });
      }
      this.options.sort(o => (o.value ? 1 : -1));
      this.selected = [];
    },

    //ERROR MESSAGE FORMAT
    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    formatCategories(value, label, supplier) {
      if (value) {
        var result = null;
        value.forEach(category => {
          if (!result) {
            result = this.supplyTypeDisplay(category);
          } else {
            result = result + ", " + this.supplyTypeDisplay(category);
          }
        });
        if (supplier.broker) result = "Broker";
        return result;
      }
    }
  }
};
</script>
